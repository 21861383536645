@font-face {
	font-family: 'LSR';
	src: url('assets/fonts/LSR_Sans-Bold_Web/LSR_Sans-Bold.woff2') format('woff2'),
		url('assets/fonts/LSR_Sans-Bold_Web/LSR_Sans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('assets/fonts/Myriad_Pro/MyriadPro-Regular.otf'),
		url('assets/fonts/Myriad_Pro/MyriadPro-Bold.otf'),
		url('assets/fonts/Myriad_Pro/MyriadPro-It.otf');
}

@font-face {
	font-family: 'Stem';
	src: url('assets/fonts/Stem/Stem-Regular.otf'), url('assets/fonts/Stem/Stem-Italic.otf');
}

body {
	font-family: 'Stem', sans-serif;
}
