@import 'antd/dist/antd.min.css';

$lsrRed: rgb(223 30 66);
$lsrRed_2: rgb(166 15 46);
$lsrRed_3: rgb(78 8 13);
$lsrGray: rgb(230 230 230);
$lsrGray_2: rgb(200 200 200);
$lsrGray_3: rgb(130 130 130);
$lsrBlack: rgb(0 0 0);

.cust-transition-1s .ant-progress-bg {
	transition: all 1s;
}

.ant-message-custom-content {
	display: flex;
	align-items: center;
	.anticon {
		padding-bottom: 4px;
	}
}

.ant-menu {
	background: transparent;

    .ant-menu-item:after {
        border: none !important;
    }

    .anticon-arrow-left {
        transform: translateY(-3px);
    }

    @media (max-width: 500px) {
        .ant-menu-title-content {
            display: none;
        }
    }
}

.ant-btn.reset {
	.anticon {
		transform: translateY(-3px);
	}
}

.ant-message .notify {
    margin-top: 86vh;
}

.ant-input-affix-wrapper-disabled input {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

/* .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $lsrRed;
    box-shadow: 0 0 0 2px rgb(223 30 66 / 20%);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $lsrRed;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $lsrRed;
}

.ant-input-affix-wrapper,
.ant-btn {
    &:focus,
    &:hover {
        border-color: $lsrRed;
        color: $lsrRed;
    }
}

.ant-btn-primary {
    border-color: $lsrRed;
    background: $lsrRed;

    &:focus,
    &:hover {
        border-color: $lsrRed_2;
        background: $lsrRed_2;
    }
} */
