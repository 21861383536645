@tailwind base;

@layer base {
	h1 {
		font-size: 30px;
		font-weight: bold;
		line-height: 37px;
	}

	h2 {
		font-size: 24px;
		font-weight: bold;
		line-height: 29px;
	}

	h3 {
		font-size: 18px;
		font-weight: bold;
		line-height: 22px;
	}

	h4 {
		font-size: 14px;
		font-weight: 800;
		line-height: 17px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
	}

	h5 {
		font-size: 12px;
		font-weight: 800;
		line-height: 15px;
		letter-spacing: 0.03em;
		text-transform: uppercase;
	}
}
