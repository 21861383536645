section.singleLayout {
	header {
		.logo {
			width: 160px;
			height: auto;
			margin-top: 40px;
		}
        @media (min-height: 780px) {
            .logo {
                width: 237px;
                margin-top: 200px;
            }
        }
	}
}
